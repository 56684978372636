.btn-space {
    margin-right: 5px;
}

body style {
    display: block;
    font: 14px monospace;
    padding: 3px;
    margin: 0 0 5px 0;
}

body,
html {
    height: 100%;
    text-align: left !important;
    padding-top: 0 !important;
}

/*bootsatrp start*/
label {
    margin-bottom: 0px !important;
}

h1,
h2,
h3 {
    /*margin-top: 5px;
    margin-bottom: 0;*/

    margin-top: 0;
    margin-bottom: 1px;
}

.form-control {
    display: block;
    width: 100%;
    height: 25px;
    /*Edited by prp*/
    padding: 0;
    /*Edited by prp*/
    font-size: 14px;
    line-height: 1.428571429;
    color: #555555;
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.LikeFormControl {
    display: block;
    width: 100%;
    height: 25px;
    padding: 0;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555555;
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.div-control {
    height: auto !important;
    background-color: none !important;
    background: none !important;
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-transition: none !important;
    transition: none !important;
}

ul,
ol {
    margin-top: 0;
    margin-bottom: 10px;
    padding: 0px;
    /*added by prp*/
}

/*bootsatrp end*/
.DateClass {
    border: 1px dotted #9b9d9e;
    border-radius: 25px;
    padding: 2px 10px;
    min-width: 150px;
}

.DateClassNoMinWidth {
    border: 1px dotted #9b9d9e;
    border-radius: 25px;
    padding: 2px 10px;
}

.SystemStyle {
    padding: 2px 10px;
    min-width: 150px;
    max-width: 150px;
    background-color: #5580B9;
    color: white;
}

.CheckNotStarted {
    padding: 2px 10px;
    max-width: 220px;
    min-width: 220px;
    background-color: #CACACA;
    color: white;
    font-size: 12px;
    cursor: pointer;
}

/*.CheckRunning{padding: 2px 10px;max-width: 220px;min-width: 220px;background-color: #F0A30A;color: white;font-size:12px;}*/
/*FEC844*/
.CheckRunning {
    color: #ffffff;
    background-color: #57a957;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, to left top, left bottom, from(#62c462), to(#57a957));
    background-image: -moz-linear-gradient(to top, #62c462, #57a957);
    background-image: -ms-linear-gradient(to top, #62c462, #57a957);
    /* background-image: -webkit-gradient(linear, to left top, left bottom, color-stop(0%, #62c462), color-stop(100%, #57a957)); */
    background-image: -webkit-linear-gradient(top, #FEC449, #fd9a0f);
    background-image: -o-linear-gradient(top, #62c462, #57a957);
    background-image: linear-gradient(to top, #FEC844, #57a957);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#62c462', endColorstr='#57a957', GradientType=0);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    border-color: #57a957 #57a957 #3d773d;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    padding: 2px 10px;
    max-width: 220px;
    min-width: 220px;
    font-size: 12px;
    cursor: pointer;
}

/*.CheckCompleted {padding: 2px 10px;max-width: 220px;min-width: 220px;background-color: #60A917;color: white;font-size:12px;}*/
/*62B555*/
.CheckCompleted {
    color: #ffffff;
    background-color: #57a957;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, left bottom, from(#62c462), to(#57a957));
    background-image: -moz-linear-gradient(top, #62c462, #57a957);
    background-image: -ms-linear-gradient(top, #62c462, #57a957);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #62c462), color-stop(100%, #57a957));
    background-image: -webkit-linear-gradient(top, #62c462, #57a957);
    background-image: -o-linear-gradient(top, #62c462, #57a957);
    background-image: linear-gradient(to top, #62c462, #57a957);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#62c462', endColorstr='#57a957', GradientType=0);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    border-color: #57a957 #57a957 #3d773d;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    padding: 2px 10px;
    max-width: 220px;
    min-width: 220px;
    font-size: 12px;
    /* cursor: pointer; */
    border-radius: 0 !important;
}

/*.CheckFailed {padding: 2px 10px;max-width: 220px;min-width: 220px;background-color: #9A1616;color: white;font-size:12px;}*/
/*EC6962*/
.CheckFailed {
    color: #ffffff;
    background-color: #c43c35;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, left bottom, from(#ee5f5b), to(#c43c35));
    background-image: -moz-linear-gradient(top, #ee5f5b, #c43c35);
    background-image: -ms-linear-gradient(top, #ee5f5b, #c43c35);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ee5f5b), color-stop(100%, #c43c35));
    background-image: -webkit-linear-gradient(top, #ee5f5b, #c43c35);
    background-image: -o-linear-gradient(top, #ee5f5b, #c43c35);
    background-image: linear-gradient(to top, #ee5f5b, #c43c35);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ee5f5b', endColorstr='#c43c35', GradientType=0);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    border-color: #c43c35 #c43c35 #882a25;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    padding: 2px 10px;
    max-width: 220px;
    min-width: 220px;
    font-size: 12px;
    cursor: pointer;
}

.CheckVerificationFailed {
    color: #ffffff;
    background-color: #000;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, left bottom, from(#000), to(#000));
    background-image: -moz-linear-gradient(top, #000, #000);
    background-image: -ms-linear-gradient(top, #000, #000);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #000), color-stop(100%, #000));
    background-image: -webkit-linear-gradient(top, #000, #000);
    background-image: -o-linear-gradient(top, #000, #000);
    background-image: linear-gradient(to top, #000, #000);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000', endColorstr='#000', GradientType=0);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    border-color: #000 #000 #882a25;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    padding: 2px 10px;
    max-width: 220px;
    min-width: 220px;
    font-size: 12px;
    cursor: pointer;
}

.CheckBlankCheck {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    font-size: 11px;
    font-family: cursive;
    cursor: pointer;
    min-width: 220px;
    max-width: 220px;
}

.TaskNotStarted {
    padding: 2px 10px;
    max-width: 150px;
    min-width: 150px;
    background-color: #CACACA;
    color: black;
    cursor: pointer;
    font-size: 12px;
}

/*.TaskRunning{padding: 2px 10px;max-width: 150px;min-width: 150px;background-color: #F7B835;color: black;cursor:pointer;font-size:12px;}*/
/*FED680*/
.TaskRunning {
    color: #ffffff;
    background-color: #57a957;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, left bottom, from(#62c462), to(#57a957));
    background-image: -moz-linear-gradient(top, #62c462, #57a957);
    background-image: -ms-linear-gradient(top, #62c462, #57a957);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #62c462), color-stop(100%, #57a957));
    background-image: -webkit-linear-gradient(top, #FEC449, #fd9a0f);
    background-image: -o-linear-gradient(top, #62c462, #57a957);
    background-image: linear-gradient(to top, #FEC844, #57a957);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#62c462', endColorstr='#57a957', GradientType=0);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    border-color: #57a957 #57a957 #3d773d;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    padding: 2px 10px;
    max-width: 150px;
    min-width: 150px;
    cursor: pointer;
    font-size: 12px;
}

/*.TaskCompleted {padding: 0 08px;max-width: 150px;min-width: 150px;background-color: #60A917;color: black;cursor:pointer;font-size:12px;}*/
/*A3D19D*/
.TaskCompleted {
    color: #ffffff;
    background-color: #57a957;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, left bottom, from(#62c462), to(#57a957));
    background-image: -moz-linear-gradient(top, #62c462, #57a957);
    background-image: -ms-linear-gradient(top, #62c462, #57a957);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #62c462), color-stop(100%, #57a957));
    background-image: -webkit-linear-gradient(top, #62c462, #57a957);
    background-image: -o-linear-gradient(top, #62c462, #57a957);
    background-image: linear-gradient(to top, #62c462, #57a957);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#62c462', endColorstr='#57a957', GradientType=0);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    border-color: #57a957 #57a957 #3d773d;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    padding: 2px 10px;
    max-width: 150px;
    min-width: 150px;
    cursor: pointer;
    font-size: 12px;
}

/*.TaskFailed {padding: 0 08px;max-width: 150px;min-width: 150px;background-color:#DC1F1F ;color: black;cursor:pointer;font-size:12px;}*/
/*#F08B86*/
.TaskFailed {
    color: #ffffff;
    background-color: #c43c35;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, left bottom, from(#ee5f5b), to(#c43c35));
    background-image: -moz-linear-gradient(top, #ee5f5b, #c43c35);
    background-image: -ms-linear-gradient(top, #ee5f5b, #c43c35);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ee5f5b), color-stop(100%, #c43c35));
    background-image: -webkit-linear-gradient(top, #ee5f5b, #c43c35);
    background-image: -o-linear-gradient(top, #ee5f5b, #c43c35);
    background-image: linear-gradient(to top, #ee5f5b, #c43c35);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ee5f5b', endColorstr='#c43c35', GradientType=0);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    border-color: #c43c35 #c43c35 #882a25;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    padding: 2px 10px;
    max-width: 150px;
    min-width: 150px;
    cursor: pointer;
    font-size: 12px;
}

.DialogTable {
    border-width: 0px;
    border-style: solid;
    width: 100%;
    border-collapse: collapse;
    font-size: 11px;
}

table.check {
    text-align: center;
    border-collapse: separate;
    border-spacing: 15px;
    *border-collapse: expression('separate', cellSpacing='15px');
}

.GridViewHeaderStyle {
    background-color: #5D7B9D;
    font-weight: bold;
    color: White;
    text-align: center;
}

.GridViewHeaderStyle th {
    border-left: 1px solid #EBE9ED;
    border-right: 1px solid #EBE9ED;
}

.GridViewRowStyle {
    background-color: #F7F6F3;
    color: #333333;
}

.GridViewRowStyle td {
    padding-left: 5px;
    vertical-align: top;
    border: 1px solid #EBE9ED;
}

.GridViewAlternatingRowStyle {
    background-color: #FFFFFF;
    color: #333333;
}

.GridViewAlternatingRowStyle td {
    padding-left: 5px;
    vertical-align: top;
    border: 1px solid #EBE9ED;
}


.TableStyle {
    border-width: 1px;
    border-style: solid;
    width: 100%;
    font-size: 12px;
}

.TableHeaderStyle {
    text-align: center;
    height: 35px;
    font-size: 14px;
    color: #ffffff;
    background-color: #0064cd;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, left bottom, from(#049cdb), to(#0064cd));
    background-image: -moz-linear-gradient(top, #049cdb, #0064cd);
    background-image: -ms-linear-gradient(top, #049cdb, #0064cd);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #049cdb), color-stop(100%, #0064cd));
    background-image: -webkit-linear-gradient(top, #049cdb, #0064cd);
    background-image: -o-linear-gradient(top, #049cdb, #0064cd);
    background-image: linear-gradient(to top, #049cdb, #0064cd);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#049cdb', endColorstr='#0064cd', GradientType=0);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    border-color: #0064cd #0064cd #003f81;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}

.TableHeaderStyle th {
    border-left: 1px solid #EBE9ED;
    border-right: 1px solid #EBE9ED;
}

.TableRowStyle {
    background-color: #F7F6F3;
    /*background-color: #FFFFFF;*/
    color: #333333;
}

.TableRowStyle td {
    padding-left: 5px;
    vertical-align: top;
    border: 1px solid #EBE9ED;
    cursor: pointer;
}

.TableAlternatingRowStyle {
    background-color: #FFFFFF;
    color: #333333;
}

.TableAlternatingRowStyle td {
    padding-left: 5px;
    vertical-align: top;
    border: 1px solid #EBE9ED;
    cursor: pointer;
}

.RowStyle {
    background-color: #F7F6F3;
    color: #333333;
}

.RowStyle td {
    padding-left: 5px;
    vertical-align: middle;
    border: 1px solid #EBE9ED;
    cursor: pointer;
}

.AlternatingRowStyle {
    background-color: #FFFFFF;
    color: #333333;
}

.AlternatingRowStyle td {
    padding-left: 5px;
    vertical-align: middle;
    border: 1px solid #EBE9ED;
    cursor: pointer;
}


/*.SystemFailed{padding: 2px 10px;max-width: 180px;min-width: 180px;height:40px; background-color: #F08B86;color: rgb(99, 92, 92);cursor:pointer;}*/
.SystemFailed {
    color: #ffffff;
    background-color: #c43c35;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, left bottom, from(#ee5f5b), to(#c43c35));
    background-image: -moz-linear-gradient(top, #ee5f5b, #c43c35);
    background-image: -ms-linear-gradient(top, #ee5f5b, #c43c35);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ee5f5b), color-stop(100%, #c43c35));
    background-image: -webkit-linear-gradient(top, #ee5f5b, #c43c35);
    background-image: -o-linear-gradient(top, #ee5f5b, #c43c35);
    background-image: linear-gradient(to top, #ee5f5b, #c43c35);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ee5f5b', endColorstr='#c43c35', GradientType=0);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    border-color: #c43c35 #c43c35 #882a25;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    padding: 2px 10px;
    max-width: 180px;
    min-width: 180px;
    height: 40px;
    cursor: pointer;
}

/*.SystemCompleted{padding: 2px 10px;max-width: 180px;min-width: 180px;height:40px;background-color: #A3D19D;color: rgb(99, 92, 92);cursor:pointer;}*/
.SystemCompleted {
    color: #ffffff;
    background-color: #57a957;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, to left top, left bottom, from(#62c462), to(#57a957));
    background-image: -moz-linear-gradient(to top, #62c462, #57a957);
    background-image: -ms-linear-gradient(to top, #62c462, #57a957);
    background-image: -webkit-gradient(linear, to left top, left bottom, color-stop(0%, #62c462), color-stop(100%, #57a957));
    background-image: -webkit-linear-gradient(to top, #62c462, #57a957);
    background-image: -o-linear-gradient(to top, #62c462, #57a957);
    background-image: linear-gradient(to top, #62c462, #57a957);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#62c462', endColorstr='#57a957', GradientType=0);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    border-color: #57a957 #57a957 #3d773d;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    padding: 2px 10px;
    max-width: 180px;
    min-width: 180px;
    height: 40px;
    cursor: pointer;
}

/*.SystemRunning{padding: 2px 10px;max-width: 180px;min-width: 180px;height:40px;background-color: #FED680;color: rgb(99, 92, 92);cursor:pointer;}*/
/*#FEC449 #FEB621  */
.SystemRunning {
    color: #ffffff;
    background-color: #57a957;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, left bottom, from(#62c462), to(#57a957));
    background-image: -moz-linear-gradient(top, #62c462, #57a957);
    background-image: -ms-linear-gradient(top, #62c462, #57a957);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #62c462), color-stop(100%, #57a957));
    background-image: -webkit-linear-gradient(top, #FEC449, #fd9a0f);
    /*background-image: -webkit-linear-gradient(top, #fd9a0f, #F1960A);*/
    background-image: -o-linear-gradient(top, #62c462, #57a957);
    background-image: linear-gradient(to top, #FEC844, #57a957);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#62c462', endColorstr='#57a957', GradientType=0);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    border-color: #57a957 #57a957 #3d773d;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    padding: 2px 10px;
    max-width: 180px;
    min-width: 180px;
    height: 40px;
    cursor: pointer;
}

.SystemNotStarted {
    padding: 2px 10px;
    max-width: 180px;
    min-width: 180px;
    height: 40px;
    background-color: #CACACA;
    color: rgb(99, 92, 92);
    cursor: pointer;
}

.VerificationFailed {
    color: #ffffff;
    font-weight: bold;
    background-color: #000;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, left bottom, from(#000), to(#000));
    background-image: -moz-linear-gradient(top, #000, #000);
    background-image: -ms-linear-gradient(top, #000, #000);
    background-image: -webkit-gradient(linear, to left top, to left bottom, color-stop(0%, #000), color-stop(100%, #000));
    background-image: -webkit-linear-gradient(to top, #000, #000);
    background-image: -o-linear-gradient(to top, #000, #000);
    background-image: linear-gradient(to top, #000, #000);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000', endColorstr='#000', GradientType=0);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    border-color: #000 #000 #882a25;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    padding: 2px 10px;
    max-width: 180px;
    min-width: 180px;
    height: 40px;
    cursor: pointer;
}

.BordeCornerAll {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.overflow {
    margin: 0 0 2em 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


/*For Div And Table*/
.divSearch {
    border: 1px solid #eee;
    background-color: #FCFCFC;
}

.divTable {}

.divTable td {
    padding: 2px 10px 5px 5px;
}

.Mendatory {
    color: red;
}

.Duration {
    font-size: 11px;
    float: right;
}

.divHighLight {
    margin-bottom: 2px !important;
    height: 20px;
    width: 237px;
}

.pHighLight {
    font-size: 11px !important;
    margin-top: 1px !important;
    margin-left: 1px !important;
}

.spanHighLight {
    float: left;
    margin-right: .3em;
}

.at-body-content {
    /*height: 100%;*/
    height: 92vh;
    width: 100%;
    max-width: 99%;
    padding-top: 0.8vh;
}

.imgFlip {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
}

.ProgressArrow {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scale(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
    width: 25px;
    height: 15px;
}

.aLink {
    /*margin-top: 35px;*/
    cursor: pointer;
    color: #038BD7;
    font-size: 14px;
    padding-right: 10px;
}

.aLink:hover {
    color: #40669A;
}

.ChildLink {
    cursor: pointer;
    color: white !important;
    font-size: 14px;
    padding-right: 10px;
}

.ChildLink:hover {
    color: #40669A;
}

.DialogTable {
    font-size: 13px;
}

.DialogTable td {
    /*margin-right: 30px;*/
    min-width: 50px;
}

table.DefinitionTable {
    width: 100%;
    border-collapse: collapse;
    font-size: 13px;
}

table.DefinitionTable td,
th {
    /*min-width: 50px;*/
    border: 1px solid #EBE9ED;
    padding: 3px 1px;
    /*border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;*/
    /*max-width: 110px;*/
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top !important;
}

table.DefinitionTable th {
    max-width: 80px !important;
}

.ui-dialog table.DefinitionTable th,
.InsideShadow table.DefinitionTable th {
    /*max-width: none !important;*/
    max-width: 10vw !important;
}

.ui-dialog table.DefinitionTable th {
    min-width: 10vw;
}

.GearSetting {
    cursor: pointer;
    margin: 0 10px;
    display: none;
    /*to hide gear setting of task definition*/
}

.lblCommand {
    padding-left: 10px;
    /*.content*/
    float: left;
    /*padding-left: 10px;*/
    /*font-weight: normal;*/
    width: 21vw;
    /*text-align: left;*/
    /*margin: 0;*/
    /*.content*/
    /*.overflow*/
    /*margin: 0 0 2em 0;*/
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    /*.overflow*/
}

.CommandGearSetting {
    cursor: pointer;
    margin: 0 10px;
    float: right;
    padding: 0;
}

.content {
    float: left;
    padding-left: 10px;
    font-weight: normal;
    /*width: 350px;*/
    width: 21vw;
    text-align: left;
    margin: 0;
}

.SqlLogo {
    width: 40px;
    float: left;
}

.PrePostCommand {
    border: solid 1px #b7b7b7;
    /*display: block;*/
    display: inline-block;
    font-weight: bold;
    /*margin-bottom: 20px;*/
    padding: 0px 0px 0px 0px;
    text-align: center;
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#ededed));
}

.wrapper {
    width: 35%;
    margin: 0 auto;
}

.GrayScale {
    /*-webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: url(grayscale.svg);
    filter: gray;*/
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    /* Firefox 10+, Firefox on Android */
    filter: gray;
    /* IE6-9 */
    -webkit-filter: grayscale(100%);
    opacity: 0.3;
    /*filter: url(filters.svg#grayscale);*/
}

input[type="radio"] {
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    -ms-appearance: checkbox;
    -o-appearance: checkbox;
    margin: 0 5px;
}

.variableType {
    max-width: none !important;
}

.NewLink {
    float: right;
    cursor: pointer;
    width: 18px;
}

.LabelClass {
    width: 250px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
    font-size: 16px;
}

.StartStop {
    width: 40px;
    vertical-align: top;
    margin: 4px;
}

.PreContent {
    display: block;
    width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: normal;
}

.Prerequisite {
    vertical-align: top;
}

fieldset {
    border: 1px solid #e5e5e5 !important;
    /*padding: 0 1.4em 1.4em 1.4em !important;*/
    padding: 0 2px 1.4em 2px !important;
    margin: 5px 2px 0 2px !important;
    /*-webkit-box-shadow: 0px 0px 0px 0px #e5e5e5;*/
    /*box-shadow: 0px 0px 0px 0px #e5e5e5;*/
    background-color: #F7F6F3;
    outline: 0;
    /*border-color: rgba(82, 168, 236, 0.8);*/
    /*-webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(82, 168, 236, 0.6);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(82, 168, 236, 0.6);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(82, 168, 236, 0.6);*/
    box-shadow: .1em .1em .2em rgba(0, 0, 0, .2);
    border-radius: .3em;
    border: .4em solid rgba(255, 255, 255, .1);
}

legend {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width: auto;
    padding: 0 10px;
    border-bottom: none;
    margin-bottom: 0px;
    border: 1px solid;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    font-family: BerkeleyMono, 'Titillium Web', sans-serif;
    font-weight: normal;
    position: relative;
    /*box-shadow: inset 0 0 0 1px rgba(53,86,129, 0.4), inset 0 0 5px rgba(53,86,129, 0.5), inset -2px 0 35px white;*/
    /*border-radius: 0 10px 0 10px;*/
    background-color: #40669A;
    color: white;
    border-color: white;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px white;
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px white;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px white;
}

.SelectedCommand {
    background: linear-gradient(#40669A, lightblue);
    color: white !important;
    height: 45px;
    /*background: #999;*/
    /* for non-css3 browsers */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#40669A', endColorstr='lightblue');
    /* for IE */
    /*background: -webkit-gradient(linear, left top, left bottom, from(#40669A), to(lightblue));*/
    /* for webkit browsers */
    /*background: -moz-linear-gradient(top, #40669A, lightblue);*/
    /* for firefox 3.6+ */
}

.SelectedTask {
    border: 1px solid #40669A;
    outline: 0;
    border-color: rgba(82, 168, 236, 0.8);
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(82, 168, 236, 0.6);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(82, 168, 236, 0.6);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 20px rgba(82, 168, 236, 0.6);
}

.SaveImage {
    width: 20px;
    float: right;
    cursor: pointer;
}

.CheckHeader {
    /*margin-top: 5px;
    padding: 5px 10px;
    font-family: 'Titillium Web', sans-serif;
    position: relative;
    box-shadow: inset 0 0 0 1px rgba(53,86,129, 0.4), inset 0 0 5px rgba(53,86,129, 0.5), inset -2px 0 35px grey;
    border-radius: 0 10px 0 10px;
    background-color: #353535;
    color: white;*/
    /*margin-top: 0.8vh;*/
    padding: 0.8vh 0.8vw;
    font-family: BerkeleyMono, 'Titillium Web', sans-serif;
    position: relative;
    box-shadow: inset 0 0 0 1px rgba(53, 86, 129, 0.4), inset 0 0 5px rgba(53, 86, 129, 0.5), inset -2px 0 35px grey;
    border-radius: 0 0.8vw 0 0.8vw;
    background-color: #353535;
    color: white;
    max-height: 8vh;
}

.BorderRight {
    border-right: 1px solid #e5e5e5 !important;
    padding: 0 5px 0 5px !important;
    margin: 0 0 1.5em 0 !important;
    outline: 0;
    /*box-shadow: .1em .1em .2em rgba(0, 0, 0, .2);*/
    box-shadow: 0 0 .4em rgba(0, 0, 0, .2);
    border-radius: .3em;
    border: .4em solid rgba(255, 255, 255, .1);
    background-color: white;
}

.BorderLeft {
    border-left: 1px solid #e5e5e5 !important;
    padding: 0 5px 0 5px !important;
    margin: 0 0 1.5em 0 !important;
    outline: 0;
    /*box-shadow: -1px .1em .1em rgba(0, 0, 0, .2);*/
    box-shadow: 0 0 .4em rgba(0, 0, 0, .2);
    border-radius: .3em;
    border: .4em solid rgba(255, 255, 255, .1);
    background-color: white;
}

/*input[type="text"], select, textarea {
    max-width: 210px !important;
}*/

textarea {
    word-wrap: break-word;
    -ms-word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.remove {
    width: 28px;
    height: 10px;
    background: url('/src/images/chosen-sprite.png') -182px 0;
    display: inline-block;
    cursor: pointer;
}

.RemoveVariable {
    float: right;
    height: 15px;
    background: url('/src/images/Delete1.png');
    cursor: pointer;
}

.added li {
    display: inline-block;
    /*background-color:gray;*/
    /*margin: 5px 5px 0 0;*/
    position: relative;
    margin: 3px 0 3px 5px;
    padding: 3px 2px 3px 5px;
    border: 1px solid #aaa;
    border-radius: 3px;
    background-color: #e4e4e4;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), color-stop(100%, #eeeeee));
    background-image: -webkit-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
    background-image: -moz-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
    background-image: -o-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
    background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
    background-clip: padding-box;
    box-shadow: 0 0 2px white inset, 0 1px 0 rgba(0, 0, 0, 0.05);
    color: #333;
    line-height: 13px;
    cursor: default;
}

.variableValue,
.variableName {
    max-width: 120px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.DivContainer {
    /*height: 100% !important;*/
    height: 540px;
    overflow: auto;
    display: block !important;
    /*border: 1px gray solid;*/
    /*border-radius: 5px;
    -webkit-box-shadow: 0 0 6px gray inset;*/
}

.divCheckVariables {
    /*height: 34.5vh;*/
    min-height: 34vh;
    max-height: 34vh;
    display: block !important;
    width: 100% !important;
    overflow-x: hidden !important;
}

.divCommandProperties {
    /*height: 400px;*/
    height: 60vh;
    /*overflow: auto !important;*/
    display: block !important;
    width: 100% !important;
    /*overflow-x:hidden !important;*/
}

.InsideShadow {
    /*height: 100% !important;
    max-height: 100% !important;*/
    /*width: 100%;*/
    /*display:table;*/
}

.SpanValue {
    float: left;
    max-width: 60px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.ContinueOnError {
    float: right;
    cursor: default;
    padding-right: 5px;
}

.Progress {
    width: 100% !important;
    text-align: center !important;
    background: none !important;
}

.CommandContent {
    float: right;
    /*width: 20px;*/
    padding: 0 2px;
    cursor: pointer;
    background-color: #57a957;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, left bottom, from(white), to(silver));
    background-image: -moz-linear-gradient(top, white, silver);
    background-image: -ms-linear-gradient(top, white, silver);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, silver));
    background-image: -webkit-linear-gradient(top, white, silver);
    background-image: -o-linear-gradient(top, white, silver);
    background-image: linear-gradient(to top, white, silver);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='white', endColorstr='silver', GradientType=0);
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 5px;
    border: 1px solid #C0C0C0;
    text-shadow: 1px 1px 0px rgba(255, 255, 255, .5);
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, .2);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, .2);
}

.TestEngine {
    font-size: 14px;
    font-weight: bold;
    color: white;
    margin: 0px 10px;
    text-align: left;
    /*display: inline-block;*/
}

/*fieldset::-webkit-scrollbar-track{
border:1px gray solid;
border-radius:10px;
-webkit-box-shadow:0 0 6px gray inset;
}*/


/*********************** Dropdown with Textbox ***********************/
#Append {
    position: relative;
}

.TextBox {
    width: 140px;
    position: relative;
    /*z-index: 9999;*/
    z-index: 1001;
}

.DropDown {
    width: 160px;
    position: absolute;
    top: 0;
    left: 0;
    /*padding: 0;
    margin: 0;*/
}

.ui-datepicker {
    z-index: 9999 !important;
}

/*********************** Dropdown with Textbox ***********************/
.ScheduleDivs {
    display: none;
}

table.NoBorderTable {
    width: 100%;
}

table.NoBorderTable td,
th {
    border: 0px !important;
    padding: 2px;
    /*border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;*/
    max-width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

table.NoBorderTable th {
    width: 50px;
}

.chkWeekly,
.chkMonthly {
    margin: 2px 1px 2px 5px !important;
}

.CustomerDRP {
    min-width: 100px;
    margin: 15px;
    /*-webkit-appearance: none;*/
    /*REMOVES DEFAULT CHROME & SAFARI STYLE*/
    -moz-appearance: none;
    /*REMOVES DEFAULT FIREFOX STYLE*/
    border: 0 !important;
    /*REMOVES BORDER*/
    background-color: #bdc3c7;
    /*color: #fff;*/
    -webkit-border-radius: 5px;
    border-radius: 5px;
    font-size: 14px;
    padding: 2px;
    cursor: pointer;
}

.tblUserDashboard {
    width: 95%;
    margin: 5vw 2vw;
}

.tblUserDashboard td {
    color: #40669a;
    width: 10vw;
    height: 10vh;
    padding-left: 7vw;
}

.tblUserDashboard td:hover {
    background-color: #40669A;
    color: white;
    border-radius: 1vw;
    cursor: pointer;
}

td.divider {
    height: 0;
    border-bottom: 1px solid #40669A;
    padding: 1vh 0 !important;
}

td.divider:hover {
    background-color: white !important;
    color: #40669A;
    border-radius: 0;
    cursor: initial;
}


.menu ul {
    border-top: 1px solid #258ecd;
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu li {
    /*border-bottom: 1px solid #258ecd;
    font-family: 'Open Sans', sans-serif;
    line-height: 60px;
    padding-bottom: 3px;
    padding-left: 20px;
    padding-top: 3px;
    border-radius: 4px;*/
}

.menu li:hover {
    background-color: #40669A;
    color: white;
}

.menu a {
    color: #afdefa;
    font-size: 15px;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    border-bottom: 1px solid #258ecd;
    font-family: BerkeleyMono, 'Open Sans', sans-serif;
    line-height: 60px;
    padding-bottom: 3px;
    padding-left: 20px;
    padding-top: 3px;
    border-radius: 4px;
}

.menu {
    background: #47a3da repeat left top;
    right: 0px;
    /*top: 51px;*/
    top: 7vh;
    /* start off behind the scenes */
    height: 100%;
    position: fixed;
    width: 285px;
    /*margin-top:-0.8vh;*/
}

.RepeterBlock {
    /*width: 150px;
    height: 150px;
    background-color: #16a085;
    border-radius: 2vh;*/
}

/*.RepeterBlock:hover {
        background-color: #47a3da;
        background-color: #40669A;
    }*/

.RepeterInnerBlock {
    /*margin: 0 auto;
    text-align: center;
    padding-top: 60px;*/
}

.RepeterInnerBlock a {
    color: #ecf0f1;
    font-size: large;
    width: 150px;
    height: 150px;
    background-color: #16a085;
    border-radius: 2vh;
    margin: 0 auto;
    text-align: center;
    padding-top: 60px;
    display: block;
}

.RepeterInnerBlock a:hover {
    background-color: #47a3da;
    background-color: #40669A;
}

.MultiSelect {
    border-color: rgb(204, 204, 204);
    min-width: 277px;
    width: 277px;
}

input[type="button"] {
    font-weight: 500;
}

input[type="button"]:disabled {
    color: whitesmoke;
    font-weight: 100;
    background-image: -webkit-linear-gradient(top, #40669A, gray) !important;
}

#imgMenu {
    float: left;
    margin: 1.2vh 0 0 0;
    /*background: url(../Images/Menu.png);*/
    width: 3vw;
    background-repeat: no-repeat;
    height: 5vh;
    cursor: pointer;
    display: block;
}

.SubHeader {
    background-color: #40669a;
    background-image: none;
    /* color: gray; */
    /*border: 1px solid gray;*/
    height: 20px;
}

.imgRotate {
    -ms-transform: rotate(90deg);
    /* IE 9 */
    -webkit-transform: rotate(90deg);
    /* Chrome, Safari, Opera */
    transform: rotate(90deg);
}

.imgRotate75 {
    -ms-transform: rotate(75deg);
    /* IE 9 */
    -webkit-transform: rotate(75deg);
    /* Chrome, Safari, Opera */
    transform: rotate(75deg);
}

td.tdArrowBorder {
    width: 1vw;
    border-right: 0;
}

td.tdArrowBorder+td {
    border-left: 0;
    border-right: 0;
}

.BlankSpace {
    background-color: white;
    border: 0 !important;
}

td.tdCreateBorder {
    width: 1vw;
    border-left: 0;
    padding: 0;
}

.SystemTD {
    padding-right: 30px;
    vertical-align: top;
}

input[name="CreateType"],
input[name="CreateTypeCheck"] {
    margin: 0 !important;
    -webkit-appearance: radio;
    -moz-appearance: radio;
    -ms-appearance: radio;
    -o-appearance: radio;
}

input[name="rdBlankCheck"] {
    margin: 5px !important;
    -webkit-appearance: radio;
    -moz-appearance: radio;
    -ms-appearance: radio;
    -o-appearance: radio;
}

input[name="rdMonthlyType"],
input[name="rdEditMonthlyType"] {
    margin: -2px !important;
    -webkit-appearance: radio;
    -moz-appearance: radio;
    -ms-appearance: radio;
    -o-appearance: radio;
}

.trCopy,
.trCopyCheck {
    display: none;
}

li.liDefiniation {
    list-style-type: none;
    padding-left: 2vw;
    display: none;
}

.chkSystems,
.ExpandDefs {
    display: inline !important;
}

.TableStyle tr:last-child {
    border-bottom: 1px solid #EBE9ED;
}

.tdSchedule {
    border-right: 0 !important;
}

select:disabled {
    background-color: rgb(235, 235, 228);
}

.trHightlight {
    outline: 1px solid #66afe9;
    outline-offset: 5px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.BlankCheck {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    font-size: 11px;
    font-family: cursive;
    cursor: pointer;
    min-width: 180px;
    max-width: 180px;
}

.BlankCheckDotted {
    /*-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);*/
    /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);*/
    cursor: pointer;
    min-width: 180px;
    max-width: 180px;
    border: 2px dotted rgba(102, 175, 233, 0.6);
    border-radius: 4px;
}

.BlankCheckDotted img {
    float: right;
    margin-top: -15px;
}

.BlankCheckFilled {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    font-size: 11px;
    font-family: cursive;
    cursor: pointer;
    min-width: 180px;
    max-width: 180px;
}

#ulBlankCheck li {
    list-style-type: none;
}

td.tdHighlight {
    /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 20px lime;*/
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 15px yellow;
    border-radius: 1vh;
}

#ulBlankCheck {
    height: 30px;
    /*width: 150px;*/
    border: 1px #000 solid;
    border-radius: 1vh;
}

#ulBlankCheck li {
    padding: 5px 10px;
    z-index: 2;
}

#ulBlankCheck li:not(.init) {
    float: left;
    display: none;
    background: #ddd;
    width: 100%;
}

#ulBlankCheck li:not(.init):hover,
ul li.selected:not(.init) {
    background: #09f;
}

li.init {
    cursor: pointer;
}

#ulBlankCheck li.init span {
    background-image: url(/src/images/SubChecks.png);
    background-repeat: no-repeat;
    float: right;
    width: 2vw;
    height: 5vh;
    background-size: 1vw;
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 20px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 20px rgba(0, 0, 0, 0.075);
}

.trSchedule {
    border: 1px solid #eee;
    background-color: #FCFDE9;
    /*border-left: 0 !important;
    border-right: 0 !important;*/
}

.trSchedule td,
th {
    border: 0 !important;
    vertical-align: middle;
}


.dropdown-menu {
    position: fixed;
    top: inherit;
    left: auto;
}

#ulMultiSelectdrp {
    max-height: 300px;
    overflow: auto;
    /*width:89px !important;*/
}

input[name="chkEditWeekly"] {
    margin: 2px 2px 0 0;
}

.DeActivDefinition {
    opacity: 0.6;
    color: black;
}

input[type="text"],
input[type="password"],
select {
    max-width: 18vw !important;
}

.DefaultViePanel {
    padding-top: 1.5vh !important;
    border: 1px solid gray;
    border-radius: 1vw;
    /*width: 77vw;*/
    height: 80vh;
}

.span_DefaultViePanel {
    color: #40669A;
    margin-top: 1.5vh;
    font-family: cursive;
    font-size: 5vh;
    display: block;
    border-radius: 1vh;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px white;
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px white;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px white;
    /*width: 77vw;*/
    padding: 0;
    margin-bottom: 1vh;
    border-bottom: 1px solid #e5e5e5;
    background-color: #F7F6F3;
    text-align: center;
}

/*override bootstrap.css*/
.btn {
    padding: 6px 2px;
    /*prp*/
    font-size: 12px;
}

.btn-group>.btn:first-child {
    max-width: 10vw;
}

.DownloadXML {
    cursor: pointer;
}

div#dialogSystem tr td:nth-child(1) {
    min-width: 9vw !important;
}

div#dialogSystem tr td:nth-child(2) {
    max-width: 15vw;
}

div#dialogSystem tr td,
div#dialogSystem tr th {
    padding-top: 0.5vh;
    vertical-align: top !important;
    height: 5vh;
}

#tableOverView tr td:hover {
    /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 20px lime;*/
    /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 15px yellow; */
    border-radius: 1vh;
}

Table.TableStyleVerification {
    border-width: 1px;
    border-style: solid;
    width: 100%;
    font-size: 12px;
}

Table.TableStyleVerification th {
    max-width: none;
}

Table.TableStyleVerification td {
    vertical-align: middle;
}

.SpanControll {
    display: none;
}

span.SpanControll input.timepick {
    width: 3.5vw;
}

td.tdMaxAllowHours span.SpanControll input {
    width: 3.5vw;
}

a.btnEdit,
a.aShowLogs {
    color: #40669A;
    text-decoration: underline;
    cursor: pointer;
}

#aCustomerName {
    padding: 0;
    padding-top: 2vh;
    padding-left: 1vw;
}

#center-wrapper {
    height: 100% !important;
}

#btnExport:focus {
    background-image: -webkit-linear-gradient(top, #049cdb, #0064cd) !important;
    background-color: #0064cd;
}

.aDashboardLink {
    font-size: 12px;
    font-weight: 500;
    color: #40669a !important;
    text-decoration: underline;
    line-height: 0px !important;
    cursor: pointer !important;
}

.welcomeName {
    text-align: center;
    margin: 10px;
    width: 70px;
    line-height: 34px;
    padding-right: 20px;
    font-size: 12px;
}

.copy-box{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid #216C17;
}

.copy-box-error{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid #6c1749;
}