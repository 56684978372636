.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table.addChkMrk {
  text-align: left;
  width: 100%;
}

table.checkv2 {
  text-align: left;
  border-collapse: separate;
  border-spacing: 15px;
  width: 100%;
  *border-collapse: expression('separate', cellSpacing = '15px');
}


table.check {
  text-align: center;
  border-collapse: separate;
  border-spacing: 15px;
  *border-collapse: expression('separate', cellSpacing = '15px');
}

@font-face {
  font-family: "BerkeleyMono";
  src: local("BerkeleyMono"), url(./fonts/BerkeleyMono/BerkeleyMono-Regular.woff) format('woff');
}

@font-face {
    font-family: 'BerkeleyMono';
    font-weight: 900;
    src: local('BerkeleyMono'), url(./fonts/BerkeleyMono/BerkeleyMono-Bold.woff) format('woff');
}

@font-face {
    font-family: 'BerkeleyMono';
    font-weight: 900;
    src: local('BerkeleyMono'), url(./fonts/BerkeleyMono/BerkeleyMono-Italic.woff) format('woff');
}

#block_container {
  text-align: left;

}
#block_container > div {
  display: inline-block;
  vertical-align: middle;
  padding-right: 15px;
}

.datePicker {
  font-size: 11px;
}

.ArrowLink:hover
{
  cursor: pointer;
}

.divcontainerFlexStart {
  display: flex;
  justify-content: flex-start;
  display: inline-block;
}

.divcontainerFlexEnd {
  display: flex;
  justify-content: flex-end;
}
.divitemFlexStart {
  display: inline-block;
  margin: 2px;
  justify-content: flex-start;
}
.divitemFlexEnd {
  display: inline-block;
  margin: 2px;
  justify-content: flex-end;
}